@import "bootstrap";
@import "bootstrap-vue";
@import "layout/fonts.inc.css";
@import "layout/nav.inc.css";
@import "layout/variables.inc.css";
@import "layout/grid.inc.css";
@import "layout/dialog.inc.css";
@import "cookieconsent/build/cookieconsent.min.css";
@import 'baguettebox.js';
@import "material-colors/dist/colors.var.css";

html, body {
    font-family: "Bitter", Sans-Serif !important;
    margin: 0;
    padding: 0;
    position: relative;
}

.page-header {
    color: var(--textMutedColour);

    &::after {
        content: "";
        border-bottom: 1px solid var(--borderColour);
        display: block;
    }
}

.banner {
    background: #ffca28 !important;
	/* background: --var(--md-amber-400); */
}

h1 {
    @media(--media-xs) {
        font-size: 2rem;
    }
}

img {
    max-width: 100%;
    max-height: 100%;

    &[data-src] {
        /* opacity: 0; */

        &:not(.initial) {
            transition: opacity 1s;
        }
        
        &.initial, &.loaded, &.error {
            opacity: 1;
        }

        &:not([src]) {
            visibility: hidden;
        }
    }
}

.row {
    flex-grow: 1;
}

.btn-brand, .badge-brand {
    background-color: var(--brandColour);
    color: #fff;
    border-color: var(--brandColour);

    &:hover, &:hover {
        color: #fff;
        background-color: var(--brandColourDarker);
    }
}

.btn-group-toggle .btn-brand {

    &:not(.active) {
        background-color: #fff;
        color: var(--brandColourLighter);
    }

    &.active {
        background-color: var(--brandColour);

    }

    &:hover {
        background-color: var(--brandColourLighter);
        color: #fff;
    }

    &.focus {
        box-shadow: 0 0 0.2rem 0.05rem var(--brandColourLighter);
    }
}

.border-brand {
    border-color: var(--brandColour);
}

.form-control, .custom-select, .btn-brand {
    &:focus {
        border-color: var(--brandColourLighter);
        box-shadow: 0 0 0.2rem 0.05rem var(--brandColourLighter);
    }
}

.custom-control-input {
    
    &:checked ~ .custom-control-label::before {
        background-color: var(--brandColour);
        border-color: var(--brandColourDarker);
    }

    &:focus ~ .custom-control-label::before {
        box-shadow: 0 0 0.2rem 0.05rem var(--brandColourLighter);
    }

    &:not(:disabled) {
        &:focus ~ .custom-control-label::before {
            border-color: var(--brandColourLighter);
        }

        &:active ~ .custom-control-label::before {
            opacity: 0.5;
            background-color: var(--brandColourLighter);
            border-color: var(--brandColourLighter);
        }
    }
}

.dropdown-menu {
    box-shadow: var(--shadow-1);
}

a, a:active, a:focus {
    outline: none !important;
}

.card-link {
    color: var(--textColour) !important;

    &:hover {
        color: var(--textMutedColour) !important;
        background: var(--hoverBgColour);
    }

    &.img {
        background-position: right;
        background-repeat: no-repeat;
        background-size: contain;

        &.map {
            background-image: url('../img/site/card-link-map.png');
        }

        &.frames {
            background-image: url('../img/site/card-link-frames.png');
        }

        &.frame {
            background-image: url('../img/site/card-link-frame.png');
        }

        &.lengths {
            background-image: url('../img/site/card-link-lengths.png');
        }
    }
}

.container {
    @media (--media-xxl) {
        max-width: var(--viewport-xxl);
    }
}

@media (--media-xxl) {
    .col-xxl-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
    .col-xxl-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-xxl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-xxl-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-xxl-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .col-xxl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-xxl-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
    .col-xxl-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-xxl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-xxl-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    .col-xxl-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
    .col-xxl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.sticky-top {
    z-index: 999 !important;
}