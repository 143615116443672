.grid {
    margin-left: -7.5px;
    margin-right: -7.5px;

    & a:not(.btn) {
        color: var(--textColour);
        padding-bottom: 15px;
        padding-left: 7.5px;
        padding-right: 7.5px;
    }

    & a:hover {
        text-decoration: none;

        & .card {
            border-color: var(--borderColourMuted);
            background-color: var(--hoverBgColour);
        }
    }

    & .card {
        height: 100%;

        & .card-header {
            height: 0;
            padding: 0;
            position: relative;

            &.aspect-5-4 {
                padding-top: 80%;
            }

            &.aspect-4-3 {
                padding-top: 75%;
            }

            &.aspect-16-9 {
                padding-top: 56.25%;
            }

            &.aspect-1-1 {
                padding-top: 100%;
            }

            &.aspect-4-5 {
                padding-top: 125%;
            }

            & .aspect-box {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
            }

        }

        & .card-body {
            padding: 0.75rem 1.25rem;
            
            & span, & small {
                display: block;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                line-height: auto;
            }

            & .tag {
                font-size: 1rem;
                position: absolute;
                top: 0.8rem;
                color: var(--white);
                padding: 0.1rem 0.25rem;
                opacity: 0.9;
                box-shadow: var(--shadow-2);
                background: var(--md-grey-500);

                &.multiline {
                    line-height: 0.8rem;
                    padding-bottom: 0.5rem;

                    &.left {
                        padding-right: 0.5rem;
                    }
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 100%;
                    border-style: solid;
                    border-color: var(--md-grey-700) transparent;
                    display: block;
                    width: 0;
                }

                &.right,&:not(.left) {
                    right: -0.25rem;
                    border-radius: 0.1rem 0.1rem 0 0.1rem;

                    &:before {
                        right: 0;
                        border-width: 0.25rem 0.25rem 0px 0px;
                    }
                }

                &.left {
                    left: -0.25rem;
                    border-radius: 0.1rem 0.1rem 0.1rem 0;

                    &:before {
                        left: 0;
                        border-width: 0.25rem 0px 0px 0.25rem;
                    }
                }

                &.green {
                    background: var(--md-green-400);

                    &:before {
                        border-color: var(--md-green-700) transparent;
                    }
                }

                &.red {
                    background: var(--md-red-400);

                    &:before {
                        border-color: var(--md-red-700) transparent;
                    }
                }

                &.yellow {
                    background: var(--md-amber-400);

                    &:before {
                        border-color: var(--md-yellow-700) transparent;
                    }
                }

                &.lightgrey {
                    background: var(--md-grey-200);
                    color: var(--textColour);
                }

                &.brand {
                    background: var(--brandColour);
                }

                & small .material-icons {
                    font-size: 0.8rem;
                }
            }
        }
    }
}