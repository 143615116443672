.topbar {    
    background: var(--brandColourDarker);
    color: #fff;

    & .navbar-logo {
        background: url('../../img/site/fine-art-guild.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    } 

    & .navbar-meta {
        & .brand {
            font-size: 1.7rem;
            line-height: 2rem;

            @media(--media-sm) {
                font-size: 2.1rem;
                line-height: 2.5rem;
            }
        }
    }

    & a {
        color: var(--textMutedColor);

        &:hover {
            text-decoration: none;
            opacity: 0.7;
        }
    }

    & .navbar-contact a {
        margin-right: 1rem;

        @media(--media-xl) {
            line-height: 2.2rem;
        }
    }

    & .navbar-right {
        text-align: right;

        & a {

            &.nav-link {
                display: inline;
                padding: 0 0 0 1rem;
                line-height: 1rem;
            }

            &.cartLink {
                line-height: 3.2rem;
            }
        }

        & ul {
            margin: 0;
            padding: 0;

            & li {
                list-style-type: none;
                display: inline;
            }

            font-size: 0.8rem;
        }

        & .dropdown-menu {
            left: auto;
            right: 0;
        }
    }
}

.navigation {
    padding: 0.5rem;

    @media(--media-lg) {
        padding: 0;
    }

    background: var(--brandColour);

    & .navbar-toggler {
        border: 0;
        color: #fff;
        font-size: 1rem;
        padding-top: 0;
        padding-left: 0;
    }

    & .navbar-nav { 
        width: 100%;

        & li {
            flex-grow: 1;
        }
    }

    & a.nav-link, & a.cartLink {
        color: #fff !important;

        &:hover {
            opacity: 0.7;
        }
    }
}

.navbar-breadcrumbs {
    background: #e9ecefbb;

    & .breadcrumb {
        font-size: 0.9rem;
        padding: 0.5rem;
        background: transparent;
    }
}

#cartOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.3);
    z-index: 1030;
    padding-top: 6rem;
    visibility: hidden;
    opacity: 0;

    transition: all 0.3s ease;

    @media(--media-xs) {
        padding-top: 14rem;
    }

    @media(--media-sm) {
        padding-top: 10rem;
    }

    @media(--media-lg) {
        padding-top: 6rem;
    }

    &.visible {
        visibility: visible;
        opacity: 1;

        & .card {
            transform: translateY(0);
        }
    }

    & .card {
        opacity: 1;
        transform: translate(0, -10%);
        transition: all 0.3s ease;
        width: 700px;
        max-width: 100%;
    
        /* Arrow */
        &:after, &:before {
            bottom: 100%;
            left: 80%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        &:after {
            border-color: rgba(136, 183, 213, 0);
            border-bottom-color: white;
            border-width: 1rem;
            margin-left: -1rem;
        }
        &:before {
            border-color: transparent;
            border-bottom-color: rgba(0,0,0,0.125);
            border-width: 1rem;
            margin-left: -1rem;
        }
        /* end arrow */

        & .img {
            padding-top: 0.5rem;
            padding-right: 0;

            & img {
                max-height: 100px;
                margin: auto;
            }
        }

        & p {
            font-size: 0.9rem;
        }

        & .list-group-item {
            padding: 0.5em 1.25em;
        }

        & .card-body {
            padding: 0.5em 1.25em;
        }
    }
}

footer {

    & p {
        color: var(--textMutedColour);
        margin: 0.75rem 0;

        &:first-child {
            font-size: 0.8rem;
        }
    }

    & a {
        color: var(--textMutedColor);
    }

    & hr {
        margin: 0;
    }

    & .social {
        & a {
            display: block;
        }
    
        & a:hover {
            text-decoration: none;
            opacity: 0.7;
        }

        & svg {
            width: 1.5rem;
            margin-left: 0.5rem;
            margin-right: 0;
        }

        & .facebook {
            fill: #3B5998;
        }

        & .twitter {
            fill: #1DA1F2;
        }

        & .instagram {
            fill: #E4405F;
        }

        @media (--media-sm) {
            text-align: center;

            & a {
                flex-grow: 1;
            }

            & p:first-child {
                display: none;
            }
        }

        @media (--media-md) {
            & p:not(:first-child) {
                display: flex;
                width: 100%;
            }
        }

        @media (--media-lg) {
            display: block;
            text-align: right;

            & p:not(:first-child) {
                display: block;
            }

            & p:first-child {
                display: block;
            }
        }
    }

}