@import '@fontsource/bitter/400.css';
@import '@fontsource/material-icons/400.css';

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.2rem;
  display: inline-block;
  line-height: 1rem;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  -moz-osx-font-smoothing: grayscale;

  font-feature-settings: 'liga';

  position: relative;
  top: 0.2rem;

  &.sm { font-size: 0.8rem; }
  &.md { font-size: 1.5rem; }
  &.lg { font-size: 2rem; }
}

h1,h2,h3,h4,h5 {
  position: relative;

  & a.anchor {
    opacity: 0;
    position: absolute;
    top: 0;
    left: -1.5rem;
    color: var(--textColour);

  }

  &:hover .anchor {
    opacity: 1;
  }
}