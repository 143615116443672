@import 'variables.inc';

.modal {
    background: rgba(0,0,0,0.3);
    display: block;
    opacity: 0;
    transition: opacity 0.2s;
    overflow-y: scroll;
    z-index: -1050;

    &.show {
        opacity: 1;
        z-index: 1050;
    }

    &.fade .modal-dialog {
        transition: transform 0.2s ease-out;
        transform: scale(0.9);
    }

    &.show .modal-dialog {
        transform: none;
    }

    & .modal-content {
        box-shadow: var(--shadow-4);
    }
}

